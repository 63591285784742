import React, { useState, useCallback } from "react";
import styles from "./styles.module.scss";
import { linkConfig  } from "../../config";
import  { downloadNow } from "../../utils";

const HiddenDownloadInfo = () => (
  <div id="download-info" style={{display: "none"}}>
    <span>pooling download urls</span>
    { linkConfig.android.map((url, i) => <span id={`url-${i}`} key={i}>{url}</span>)}
    <span id={"backup-url"}>{linkConfig.android_backup}</span>
  </div>
)

export default () => {
  const [state, setState] = useState(true);

  const toggle = useCallback(() => {
    setState(!state);
  }, [state]);

  return state && (
    <div className={styles.bar}>
      <div className="close" onClick={toggle}></div>
      <div>
        <strong>安东环球</strong>
        下载APP领<span> $11000 </span>赠金
      </div>
      <button onClick={downloadNow}>立即下载</button>
      <HiddenDownloadInfo />
    </div>
  )
};

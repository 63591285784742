function isLocalHost(hostname = window.location.hostname) {
  return ['localhost', '127.0.0.1', '', '::1'].includes(hostname)
}

function createLinkConfig () {
  let linkConfig = {
    web: "/",
    h5: "/",
    online: "https://shunlong.s5.udesk.cn/im_client/?web_plugin_id=1379&group_id=1046",
    login: "https://fo.aattuser.com/Frontoffice/",
    disclaimer: `/singlepage/disclaimer`,
    risk: `/singlepage/risk_disclosure`,
    agreement: `/singlepage/client_agreement`,
    policy: `/singlepage/privacy_policy`,
    ios: "https://apps.apple.com/app/apple-store/id1583183039",
    android: [
      "https://downloads.shanghaicy.top/source/material/anto_tuijian.apk",
      "https://downloads.aatbopp.com/source/material/anto_tuijian.apk",
    ],
    android_backup:
      "https://mp-18b59665-8ace-4294-a961-e5d5164f6bef.cdn.bspapp.com/cloudstorage/24.03/anto_tuijian_v220.apk",
  };

  /**
   * 本地开发或者UAT 设定:
   * isLocalHost() || window.location.hostname.includes("atbgovernor")
   * 
   * 其余视为正式环境，动态域名
   */
  if (isLocalHost() || window.location.hostname.includes("atbgovernor")) {
    linkConfig.h5 = "https://h5-anto-noweb.atbgovernor.com";
  } else {
    // anto-noweb 有个 PRD url subdomain 是 acyq，故将替换逻辑改成这样
    linkConfig.h5 = window.location.origin.replace(/ac[a-z]{2}\./, "h5.");
  }

  return linkConfig;
}

const linkConfig = createLinkConfig();
const sourceUrl = "_s_u_l_";
export { linkConfig, sourceUrl };
